import {MouseEventHandler, useState} from "react";
import * as React from "react";

export type ActionLinkProps = {
   onEnabled?: (enabled: boolean) => void,
   preventDefault?: boolean,
   async?: boolean,
   className?: string,
   children: React.ReactNode,
   action: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
            enabledCallback?: (enable: boolean) => void) => void;
}

export default function ActionLink(props: ActionLinkProps) {
   const [enabled, setEnabled] = useState(true);

   const setEnabledWithCallback = (x = true) => {
      setEnabled(x);
      props.onEnabled && props.onEnabled(x);
   }

   const doAction: MouseEventHandler<HTMLAnchorElement> = e => {
      (props.preventDefault === undefined || props.preventDefault) && e.preventDefault();
      setEnabledWithCallback(false);
      if (props.async) {
         props.action(e, setEnabledWithCallback);
      } else {
         try {
            props.action(e);
         } finally {
            setEnabledWithCallback(true);
         }
      }
   }

   const actionLinkClass = () => {
      let css = enabled ? "action-link" : "action-link disabled";
      if (props.className) {
         css = `${css} ${props.className}`;
      }
      return css;
   }

   return <a href="#" className={actionLinkClass()} onClick={doAction}>
      {enabled && props.children}
      {!enabled && <span>Please wait...</span>}
   </a>
}
