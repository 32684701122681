import Popup from "../components/Popup";
import axios from "axios";
import ApiErrorMessage from "../components/ApiErrorMessage";

const deleteNonAxiosParams = (params, ...toBeDeleted) => {
   toBeDeleted.forEach(field => {
      if (params[field]) {
         delete params[field];
      }
   })
}

function AmogeServer<T>(url: string, params?: any, popupError: boolean = true) {
   params = params || {method: "GET"};
   if (params.body && !params.data) { // Change body param to a data param
      params.data = params.body;
   }

   const dataOnly = params.dataOnly === undefined || params.dataOnly; // Defaults to true
   const errorDataOnly = params.errorDataOnly; // Defaults to false

   deleteNonAxiosParams(params, "body", "dataOnly", "errorDataOnly");

   return new Promise<T>(function (resolve, reject) {
      axios(url, params).then(response => {
         resolve(dataOnly ? response.data : response)
      }).catch(error => {
         if (error.response) {
            if (popupError) {
               Popup.error({
                  component: <ApiErrorMessage response={error.response} onClose={() => reject(error.response)}/>,
                  transient: false
               });
            }
            reject(errorDataOnly ? error.response.data : {...error.response, popupError});
         } else {
            if (popupError) {
               Popup.error({
                  title: "Something went wrong!",
                  message: "Please try again later",
                  buttons: "OK_ONLY"
               })
            }
            reject(error.request || error.message || error);
         }
      })
   });
}

export default AmogeServer;