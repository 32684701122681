import * as React from "react";
import Popup from "./Popup";

const getButtons = function (buttonsParam: PopupButton[] | "OK_ONLY", redirect: string): PopupButton[] {
   if (buttonsParam === "OK_ONLY") {
      return [{
         text: "OK",
         action: () => {
            Popup.close();
            if (redirect) {
               window.location.href = redirect;
            }
         }
      }];
   } else {
      return buttonsParam || [];
   }
};

export enum PopupMessageType {
   Info = "info",
   Warning = "warning",
   Success = "success",
   Error = "error"
}

export type PopupButton = {
   text: string | JSX.Element,
   action: (...args: any[]) => void
}

export type PopupMessageProps = {
   id?: number,
   title?: string | JSX.Element,
   messageType?: PopupMessageType,
   message?: string | JSX.Element,
   timeout?: number
   buttons?: PopupButton[] | "OK_ONLY",
   redirect?: string,
   transient?: boolean
   component?: JSX.Element,
   css?: string,
   usePre?: boolean,
   popup?: Popup
}

export type PopupMessageState = {

}

export default class PopupMessage extends React.Component<PopupMessageProps, PopupMessageState> {
   constructor(props) {
      super(props)
      this.performAction = this.performAction.bind(this);
   }

   componentDidMount() {
      if (this.transient()) {
         setTimeout(() => this.closeMessage(), this.timeout());
      }
   }

   title(): string | JSX.Element {
      return this.props.title || "Pop-Up";
   }

   messageType(): PopupMessageType {
      return this.props.messageType || PopupMessageType.Info;
   }

   timeout(): number {
      return this.props.timeout || 5000;
   }

   buttons() {
      return getButtons(this.props.buttons, this.props.redirect);
   }

   transient(): boolean {
      let buttons = this.buttons();
      if (typeof this.props.transient === "undefined") {
         return !buttons.length && !React.isValidElement(this.props.component);
      } else {
         return this.props.transient;
      }
   }

   dialogCss() {
      return "popup-dialog " + (this.props.css || "popup-dialog-default");
   }

   simple(): boolean {
      return !this.props.component;
   }

   titleClass(): string {
      return "popup-header " + this.messageType();
   }

   disabled(): boolean {
      let messageStack = Popup.instance.state.messageStack;
      let index = messageStack.findIndex(x => x.id === this.props.id);
      return index < messageStack.length - 1;
   }

   closeMessage() {
      Popup.instance.closeMessage(this.props.id);
   };

   performAction(evt, button) {
      evt.preventDefault();
      button.action();
   }

   render() {
      const propIsComponent = React.isValidElement(this.props.component);
      const body = propIsComponent
           ? this.props.component
           : <div className="popup-simple">
              <div className={this.titleClass()}>{this.title()}</div>
              {this.props.message && <div className="popup-body">
                 {!this.props.usePre &&
                 <div style={{height: "100%", padding: "10px"}}>
                    <div>{this.props.message}</div>
                 </div>}
                 {this.props.usePre &&
                 <div style={{height: "100%", padding: "10px", overflowX: "auto"}}>
                    <pre>{this.props.message}</pre>
                 </div>}
              </div>}
              {(this.buttons().length || !this.props.message) && <div className="popup-footer">
                 {this.buttons().map(button => <a key={String(button.text)} href="#" className="popup-button"
                                                  onClick={evt => this.performAction(evt, button)}>{button.text}</a>)}
              </div>}
           </div>;

      return <div className="popup">
         <div className="popup-dialog-fade" style={{display: this.disabled() ? "block" : "none"}}/>
         <div className={this.dialogCss()}>{body}</div>
      </div>;
   }
}

