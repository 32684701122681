import * as React from "react";

export enum ActionLinkAlign {
   Right = "right",
   Left = "left"
}

export type ActionLinkGroupProps = {
   align?: ActionLinkAlign,
   className?: string,
   children: React.ReactNode
}

export function ActionLinkGroup(props: ActionLinkGroupProps) {
   let groupClass = props.align === ActionLinkAlign.Right
        ? "action-link-group right-align"
        : "action-link-group";
   if (props.className) {
      groupClass += " " + props.className;
   }
   return <div className={groupClass}>{props.children}</div>
}
