export type DynamicLogoProps = {
   narrowClass?: string
}

export default function DynamicLogo(props: DynamicLogoProps) {
   const narrowClass = props.narrowClass || "";

   const logoCss = () => {
      let logoCss = "dynamic-logo";
      if (narrowClass) {
         logoCss += " " + narrowClass;
      }
      return logoCss;
   };

   return <div className={logoCss()}>
      <img src="../img/DMC-Long-Logo.svg" alt="DMC Logo" className="wide"/>
      <img src="../img/DMC-Stacked-Logo.svg" alt="DMC Logo" className="narrow"/>
   </div>
}