import Popup from "./Popup";
import ActionLink from "./ActionLink";
import {ErrorHandlerResponse, ErrorHandlerResponseData} from "../utils/error-handling";

export type ApiErrorMessageProps = {
   response: ErrorHandlerResponse,
   onClose?: () => void
}

export default function ApiErrorMessage(props: ApiErrorMessageProps) {

   const data = props.response.data as ErrorHandlerResponseData;

   const problems = () => {
      if (data && typeof data !== "string" && data.problems) {
         return data.problems.map(problem => (typeof problem === "string"
            && {name: "", message: problem}) || problem)
      } else {
         return [];
      }
   }

   const error = () => {
      return (data && typeof data.error == "string" && data.error) ||
           `${props.response.status}: ${props.response.statusText || "Error"}`;
   }

   const hasErrorType = () => hasString( "errorType");

   const hasErrorMessage = () => hasString( "errorMessage")

   const hasString = f => data && typeof data[f] == "string";

   const asText = v => {
      if (typeof v == "string") {
         return v;
      } else if (typeof v == "object") {
         return JSON.stringify(v, null, 2);
      } else if (typeof v == "function") {
         return asText(v());
      }
   }

   const close = () => {
      Popup.close();
      props.onClose && props.onClose();
   };

   return <div className="api-error-msg">
      <div className="popup-header error">
         <div>{error()}</div>
      </div>
      <div className="popup-body">
         {hasErrorType() && <div className="error-type">
            Type: <span>{data.errorType}</span>
         </div>}
         {hasErrorMessage() && <div className="error-message">
            Message: <span>{data.errorMessage}</span>
         </div>}
         {!hasErrorType() && !hasErrorMessage() && <div className="error-message">
            <pre>{asText(data)}</pre>
         </div>}
         <div className="problem-list">
            {problems().map(problem => <div>
               <div className="problem">
                  <div className="problem-name"><span>{problem.name}</span></div>
                  <div className="problem-details"><span>{problem.message}</span></div>
               </div>
            </div>)}
         </div>
      </div>
      <div className="popup-footer">
         <ActionLink action={close}>Close</ActionLink>
      </div>
   </div>
}
